<template>
  <div class="common-layout">
    <el-container>
      <el-header width="200px">
        <el-scrollbar>
          <el-menu @select="handleSelect" mode="horizontal">
            <el-menu-item v-for="i in list" :key="i.key" :index="i.key">{{ i.name }}</el-menu-item>
          </el-menu>
        </el-scrollbar>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </div>
</template>
<script lang="js" setup>
import {useRouter} from 'vue-router'

const router = useRouter()

const list = [
  {
    key: '1',
    to: '/',
    name: '主页',
  },
  {
    key: '2',
    to: '/api-record',
    name: '访问记录查询',
  },
]

const handleSelect = (x) => {
  const to = list.filter(item => item.key === x)[0].to
  router.push(to)
}

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
