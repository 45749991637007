import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import {ID_INJECTION_KEY} from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'

createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .provide(ID_INJECTION_KEY, {
        prefix: 1024,
        current: 0,
    })
    .mount('#app')
